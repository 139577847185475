import React from 'react';
import { Grid } from '@mui/material';
import Hero from './Hero';
import Contact from './Contact';
import Discover from './Discover/Discover';
import Successful from './Successful/Successful';
import Discover2 from './Discover2/Discover2';
import Rank2 from './Rank2/Rank';
import Footer from './Footer';
import { useRef } from 'react';
import Login from './Back/Login/Login';
import Team from './Team/Team';

const Home = () => {
  const [isConnected, setIsConnected] = React.useState(false);

  const [isContactOpen, setIsContactOpen] = React.useState(false);
  const [visibleSection, setVisibleSection] = React.useState('');
  const heroRef = useRef<HTMLDivElement>(null);
  const methodologyRef = useRef<HTMLDivElement>(null);
  const keyRef = useRef<HTMLDivElement>(null);
  const pricingRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  const openContact = () => {
    setIsContactOpen(true);
    //smooth scroll
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const closeContact = () => {
    setIsContactOpen(false);
  };

  const callBack = (entries: any) => {
    entries.forEach(function (entry: any) {
      if (entry.isIntersecting) {
        setVisibleSection(entry.target.id);
      }
    });
  };

  let observer = new IntersectionObserver(callBack, {
    threshold: 0.1,
  });

  React.useEffect(() => {
    if (pricingRef.current) observer.observe(pricingRef.current as Element);
    if (keyRef.current) observer.observe(keyRef.current as Element);
    if (methodologyRef.current)
      observer.observe(methodologyRef.current as Element);
    if (footerRef.current) observer.observe(footerRef.current as Element);
    if (heroRef.current) observer.observe(heroRef.current as Element);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingRef, keyRef, methodologyRef]);

  const connect = async (email: string, password: string) => {
    const user = process.env.REACT_APP_ENV_USER;
    const pass = process.env.REACT_APP_ENV_PASSWORD;
    if (email === user && password === pass) {
      setIsConnected(true);
    }
  };

  return (
    <>
      {!isConnected ? (
        <Login connect={connect} />
      ) : (
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <Grid
            item
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              transition: 'all 1s ease',
            }}
          >
            <Hero
              isContactOpen={isContactOpen}
              openContact={() => {
                openContact();
              }}
              visibleSection={visibleSection}
              heroRef={heroRef}
            />
            <Contact open={isContactOpen} closeContact={closeContact} />
          </Grid>
          <Discover
            openContact={() => {
              openContact();
            }}
            methodologyRef={methodologyRef}
          />
          <Successful
            openContact={() => {
              openContact();
            }}
            keyRef={keyRef}
          />
          <Discover2 />
          <Rank2 openContact={() => openContact()} />
          <Team />
          <Footer
            openContact={() => {
              openContact();
            }}
            footerRef={footerRef}
          />
        </Grid>
      )}
    </>
  );
};

export default Home;

